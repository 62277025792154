// all styles here
import "./src/assets/css/bootstrap.min.css"
import "animate.css"
import "./src/assets/css/boxicons.min.css"
import "./src/assets/css/flaticon.css"
import "./src/assets/css/slick.css"
import "react-accessible-accordion/dist/fancy-example.css"
import "./src/assets/css/style.css"
import "./src/assets/css/responsive.css"

// If you want RTL style comment out the below line
// import './src/assets/css/rtl.css'

// If you want Multicolor comment out single
// import './src/assets/css/colors/brink-pink-style.css'
// import './src/assets/css/colors/pink-style.css'
// import './src/assets/css/colors/purple-style.css'

export const onClientEntry = () => {
  const userLang = navigator.language.substr(0, 2)

  if (
    userLang == "de" &&
    !window.location.pathname.startsWith("/de") &&
    !window.location.pathname?.includes("/blogs") &&
    !window.location.pathname?.includes("/testimonials") &&
    !window.location.pathname?.includes("/blog/section") &&
    !window.location.pathname?.includes("/landing")
  ) {
    window.location.pathname = `/de${window.location.pathname}`
  }
}
