exports.components = {
  "component---src-pages-404-index-de-js": () => import("./../../../src/pages/404/index.de.js" /* webpackChunkName: "component---src-pages-404-index-de-js" */),
  "component---src-pages-404-index-en-js": () => import("./../../../src/pages/404/index.en.js" /* webpackChunkName: "component---src-pages-404-index-en-js" */),
  "component---src-pages-[lang]-customs-offices-[id]-js": () => import("./../../../src/pages/[lang]/customs-offices/[id].js" /* webpackChunkName: "component---src-pages-[lang]-customs-offices-[id]-js" */),
  "component---src-pages-[lang]-customs-offices-country-[id]-js": () => import("./../../../src/pages/[lang]/customs-offices/country/[id].js" /* webpackChunkName: "component---src-pages-[lang]-customs-offices-country-[id]-js" */),
  "component---src-pages-[lang]-customs-offices-index-js": () => import("./../../../src/pages/[lang]/customs-offices/index.js" /* webpackChunkName: "component---src-pages-[lang]-customs-offices-index-js" */),
  "component---src-pages-[lang]-ports-[id]-js": () => import("./../../../src/pages/[lang]/ports/[id].js" /* webpackChunkName: "component---src-pages-[lang]-ports-[id]-js" */),
  "component---src-pages-[lang]-ports-country-[id]-js": () => import("./../../../src/pages/[lang]/ports/country/[id].js" /* webpackChunkName: "component---src-pages-[lang]-ports-country-[id]-js" */),
  "component---src-pages-[lang]-ports-index-js": () => import("./../../../src/pages/[lang]/ports/index.js" /* webpackChunkName: "component---src-pages-[lang]-ports-index-js" */),
  "component---src-pages-[lang]-tariff-numbers-[id]-js": () => import("./../../../src/pages/[lang]/tariff-numbers/[id].js" /* webpackChunkName: "component---src-pages-[lang]-tariff-numbers-[id]-js" */),
  "component---src-pages-[lang]-tariff-numbers-index-js": () => import("./../../../src/pages/[lang]/tariff-numbers/index.js" /* webpackChunkName: "component---src-pages-[lang]-tariff-numbers-index-js" */),
  "component---src-pages-aeb-index-de-js": () => import("./../../../src/pages/aeb/index.de.js" /* webpackChunkName: "component---src-pages-aeb-index-de-js" */),
  "component---src-pages-aeb-index-en-js": () => import("./../../../src/pages/aeb/index.en.js" /* webpackChunkName: "component---src-pages-aeb-index-en-js" */),
  "component---src-pages-blog-index-de-js": () => import("./../../../src/pages/blog/index.de.js" /* webpackChunkName: "component---src-pages-blog-index-de-js" */),
  "component---src-pages-blog-index-en-js": () => import("./../../../src/pages/blog/index.en.js" /* webpackChunkName: "component---src-pages-blog-index-en-js" */),
  "component---src-pages-contact-index-de-js": () => import("./../../../src/pages/contact/index.de.js" /* webpackChunkName: "component---src-pages-contact-index-de-js" */),
  "component---src-pages-contact-index-en-js": () => import("./../../../src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-csr-index-de-js": () => import("./../../../src/pages/csr/index.de.js" /* webpackChunkName: "component---src-pages-csr-index-de-js" */),
  "component---src-pages-csr-index-en-js": () => import("./../../../src/pages/csr/index.en.js" /* webpackChunkName: "component---src-pages-csr-index-en-js" */),
  "component---src-pages-customs-agents-index-de-js": () => import("./../../../src/pages/customs-agents/index.de.js" /* webpackChunkName: "component---src-pages-customs-agents-index-de-js" */),
  "component---src-pages-customs-agents-index-en-js": () => import("./../../../src/pages/customs-agents/index.en.js" /* webpackChunkName: "component---src-pages-customs-agents-index-en-js" */),
  "component---src-pages-customs-offices-[id]-js": () => import("./../../../src/pages/customs-offices/[id].js" /* webpackChunkName: "component---src-pages-customs-offices-[id]-js" */),
  "component---src-pages-customs-offices-country-[id]-js": () => import("./../../../src/pages/customs-offices/country/[id].js" /* webpackChunkName: "component---src-pages-customs-offices-country-[id]-js" */),
  "component---src-pages-customs-offices-index-js": () => import("./../../../src/pages/customs-offices/index.js" /* webpackChunkName: "component---src-pages-customs-offices-index-js" */),
  "component---src-pages-dakosy-index-de-js": () => import("./../../../src/pages/dakosy/index.de.js" /* webpackChunkName: "component---src-pages-dakosy-index-de-js" */),
  "component---src-pages-dakosy-index-en-js": () => import("./../../../src/pages/dakosy/index.en.js" /* webpackChunkName: "component---src-pages-dakosy-index-en-js" */),
  "component---src-pages-data-protection-index-de-js": () => import("./../../../src/pages/data-protection/index.de.js" /* webpackChunkName: "component---src-pages-data-protection-index-de-js" */),
  "component---src-pages-data-protection-index-en-js": () => import("./../../../src/pages/data-protection/index.en.js" /* webpackChunkName: "component---src-pages-data-protection-index-en-js" */),
  "component---src-pages-dbh-index-de-js": () => import("./../../../src/pages/dbh/index.de.js" /* webpackChunkName: "component---src-pages-dbh-index-de-js" */),
  "component---src-pages-dbh-index-en-js": () => import("./../../../src/pages/dbh/index.en.js" /* webpackChunkName: "component---src-pages-dbh-index-en-js" */),
  "component---src-pages-dxter-index-de-js": () => import("./../../../src/pages/dxter/index.de.js" /* webpackChunkName: "component---src-pages-dxter-index-de-js" */),
  "component---src-pages-dxter-index-en-js": () => import("./../../../src/pages/dxter/index.en.js" /* webpackChunkName: "component---src-pages-dxter-index-en-js" */),
  "component---src-pages-ebooks-index-de-js": () => import("./../../../src/pages/ebooks/index.de.js" /* webpackChunkName: "component---src-pages-ebooks-index-de-js" */),
  "component---src-pages-ebooks-index-en-js": () => import("./../../../src/pages/ebooks/index.en.js" /* webpackChunkName: "component---src-pages-ebooks-index-en-js" */),
  "component---src-pages-forwarding-companies-index-de-js": () => import("./../../../src/pages/forwarding-companies/index.de.js" /* webpackChunkName: "component---src-pages-forwarding-companies-index-de-js" */),
  "component---src-pages-forwarding-companies-index-en-js": () => import("./../../../src/pages/forwarding-companies/index.en.js" /* webpackChunkName: "component---src-pages-forwarding-companies-index-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-industrial-companies-index-de-js": () => import("./../../../src/pages/industrial-companies/index.de.js" /* webpackChunkName: "component---src-pages-industrial-companies-index-de-js" */),
  "component---src-pages-industrial-companies-index-en-js": () => import("./../../../src/pages/industrial-companies/index.en.js" /* webpackChunkName: "component---src-pages-industrial-companies-index-en-js" */),
  "component---src-pages-ldv-index-de-js": () => import("./../../../src/pages/ldv/index.de.js" /* webpackChunkName: "component---src-pages-ldv-index-de-js" */),
  "component---src-pages-ldv-index-en-js": () => import("./../../../src/pages/ldv/index.en.js" /* webpackChunkName: "component---src-pages-ldv-index-en-js" */),
  "component---src-pages-legal-index-de-js": () => import("./../../../src/pages/legal/index.de.js" /* webpackChunkName: "component---src-pages-legal-index-de-js" */),
  "component---src-pages-legal-index-en-js": () => import("./../../../src/pages/legal/index.en.js" /* webpackChunkName: "component---src-pages-legal-index-en-js" */),
  "component---src-pages-master-data-index-de-js": () => import("./../../../src/pages/master-data/index.de.js" /* webpackChunkName: "component---src-pages-master-data-index-de-js" */),
  "component---src-pages-master-data-index-en-js": () => import("./../../../src/pages/master-data/index.en.js" /* webpackChunkName: "component---src-pages-master-data-index-en-js" */),
  "component---src-pages-mission-index-de-js": () => import("./../../../src/pages/mission/index.de.js" /* webpackChunkName: "component---src-pages-mission-index-de-js" */),
  "component---src-pages-mission-index-en-js": () => import("./../../../src/pages/mission/index.en.js" /* webpackChunkName: "component---src-pages-mission-index-en-js" */),
  "component---src-pages-nocode-index-de-js": () => import("./../../../src/pages/nocode/index.de.js" /* webpackChunkName: "component---src-pages-nocode-index-de-js" */),
  "component---src-pages-nocode-index-en-js": () => import("./../../../src/pages/nocode/index.en.js" /* webpackChunkName: "component---src-pages-nocode-index-en-js" */),
  "component---src-pages-ports-[id]-js": () => import("./../../../src/pages/ports/[id].js" /* webpackChunkName: "component---src-pages-ports-[id]-js" */),
  "component---src-pages-ports-country-[id]-js": () => import("./../../../src/pages/ports/country/[id].js" /* webpackChunkName: "component---src-pages-ports-country-[id]-js" */),
  "component---src-pages-ports-index-js": () => import("./../../../src/pages/ports/index.js" /* webpackChunkName: "component---src-pages-ports-index-js" */),
  "component---src-pages-pricing-index-de-js": () => import("./../../../src/pages/pricing/index.de.js" /* webpackChunkName: "component---src-pages-pricing-index-de-js" */),
  "component---src-pages-pricing-index-en-js": () => import("./../../../src/pages/pricing/index.en.js" /* webpackChunkName: "component---src-pages-pricing-index-en-js" */),
  "component---src-pages-procedures-index-de-js": () => import("./../../../src/pages/procedures/index.de.js" /* webpackChunkName: "component---src-pages-procedures-index-de-js" */),
  "component---src-pages-procedures-index-en-js": () => import("./../../../src/pages/procedures/index.en.js" /* webpackChunkName: "component---src-pages-procedures-index-en-js" */),
  "component---src-pages-taric-index-de-js": () => import("./../../../src/pages/taric/index.de.js" /* webpackChunkName: "component---src-pages-taric-index-de-js" */),
  "component---src-pages-taric-index-en-js": () => import("./../../../src/pages/taric/index.en.js" /* webpackChunkName: "component---src-pages-taric-index-en-js" */),
  "component---src-pages-tariff-numbers-[id]-js": () => import("./../../../src/pages/tariff-numbers/[id].js" /* webpackChunkName: "component---src-pages-tariff-numbers-[id]-js" */),
  "component---src-pages-tariff-numbers-index-js": () => import("./../../../src/pages/tariff-numbers/index.js" /* webpackChunkName: "component---src-pages-tariff-numbers-index-js" */),
  "component---src-pages-team-index-de-js": () => import("./../../../src/pages/team/index.de.js" /* webpackChunkName: "component---src-pages-team-index-de-js" */),
  "component---src-pages-team-index-en-js": () => import("./../../../src/pages/team/index.en.js" /* webpackChunkName: "component---src-pages-team-index-en-js" */),
  "component---src-pages-terms-index-de-js": () => import("./../../../src/pages/terms/index.de.js" /* webpackChunkName: "component---src-pages-terms-index-de-js" */),
  "component---src-pages-terms-index-en-js": () => import("./../../../src/pages/terms/index.en.js" /* webpackChunkName: "component---src-pages-terms-index-en-js" */),
  "component---src-pages-widget-index-de-js": () => import("./../../../src/pages/widget/index.de.js" /* webpackChunkName: "component---src-pages-widget-index-de-js" */),
  "component---src-pages-widget-index-en-js": () => import("./../../../src/pages/widget/index.en.js" /* webpackChunkName: "component---src-pages-widget-index-en-js" */),
  "component---src-templates-blog-category-template-js": () => import("./../../../src/templates/blog-category-template.js" /* webpackChunkName: "component---src-templates-blog-category-template-js" */),
  "component---src-templates-blog-details-template-js": () => import("./../../../src/templates/blog-details-template.js" /* webpackChunkName: "component---src-templates-blog-details-template-js" */),
  "component---src-templates-integration-template-js": () => import("./../../../src/templates/integration-template.js" /* webpackChunkName: "component---src-templates-integration-template-js" */),
  "component---src-templates-landing-page-details-template-js": () => import("./../../../src/templates/landing-page-details-template.js" /* webpackChunkName: "component---src-templates-landing-page-details-template-js" */),
  "component---src-templates-testimonial-details-template-js": () => import("./../../../src/templates/testimonial-details-template.js" /* webpackChunkName: "component---src-templates-testimonial-details-template-js" */)
}

